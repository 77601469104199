import React, { Component } from 'react'

export default class InstagramTiles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imageUrls: new Array(8).fill("#"),
    }
  }

  componentDidMount() {
    this.getImageUrls();
  }

  getImageUrls() {
    fetch("/instagram_tile_urls")
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        console.error(json.error)
      }
      this.setState({imageUrls: json.urls})
    })
  }


  render() {
    const { imageUrls } = this.state;
    return (
      <>
        {
          imageUrls.map((url, index) => 
            <div key={index} className="grid-item" style={{width: "275px", height: "287px", float: "left"}}>
              <div className="instagram-tile-container">
                <a href="https://www.instagram.com/graniterocksupply/">
                  <div className="instagram-overlay" />
                  <div id="instagram-tile" className="instagram-tile" style={{backgroundImage: `url('${url}')`}}/>
                </a>
              </div>
            </div>
          )
        }
     </>
    )
  }
}
